import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import paintHands from "../images/paint-hands.png"
import styled from "styled-components"

import indexStyles from "../styles/index.module.scss"

const IndexPage = () => {
  var [email, setEmail] = useState("")
  var [signUpResponse, setSignUpResponse] = useState("")

  const newsletterSignUp = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(response => {
        if (email && response.msg.startsWith(email)) {
          setSignUpResponse("You are already subscribed!")
        } else {
          setSignUpResponse(response.msg)
        }
      })
      .catch(() => {})
  }

  const BlueBackground = styled.div`
    background-color: rgba(0, 178, 255, 0.69);
    width: 100%;
    height: 500px;
    position: relative;
  `

  const ImageBackground = styled.div`
    background-image: url(${paintHands});
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position: 25% 70%;
    opacity: 0.9;

    @media (max-width: 875px) {
      background-size: cover;
      opacity: 0.5;
    }
  `

  return (
    <Layout>
      <SEO
        title="Home"
        description="DiMarco &amp; Associates, CPAs is a full service tax and accounting firm located in the Olsdmar/Palm Harbor, FL area"
        keywords={[
          "Certified QuickBooks Consultant",
          "Tax",
          "IRS",
          "IRS Problems",
          "Tax Preparation",
          "FL",
          "Florida",
          "Palm Harbor",
          "Tampa",
          "Clearwater",
          "Pinellas",
          "Hillsborough",
          "Offer in Compromise",
          "OIC",
          "Offer and Compromise",
          "Oldsmar Incorporation",
          "Incorporation",
          "IRS Levy",
          "IRS Lien",
          "IRS Audit",
          "Audit",
          "Tampa CPA",
          "CPA",
          "Accountant",
          "CPA near me",
          "Clearwater CPA",
          "Oldsmar CPA",
          "Palm Harbor CPA",
        ]}
      />
      <BlueBackground>
        <ImageBackground></ImageBackground>
        <div className={indexStyles.imageText}>
          <h1>
            Personalized&nbsp;Accounting
            <br />
            for the <span className="avoidWrap">Modern Era</span>
          </h1>
          <h3>Transforming Taxes into a Work of Art for over 25 years</h3>
          <Link to="/contact#form">Get In Touch</Link>
        </div>
      </BlueBackground>
      <div className={indexStyles.whiteBackground}>
        <div className="container">
          <ul>
            <li>
              <img src={require("../images/lightning icon.svg")} alt="lightning icon" />
              <h5>Prudently aggressive</h5>
              <p>Cutting edge tax strategies to retain more of what you earn</p>
            </li>
            <li>
              <img src={require("../images/lightbulb icon.svg")} alt="lightbulb icon" />
              <h5>Think outside the box</h5>
              <p>Proven, innovative ideas to decrease your tax burden</p>
            </li>
            <li>
              <img src={require("../images/heart icon.svg")} alt="heart icon" />
              <h5>Passionate about taxes</h5>
              <p>We find the hidden key in the tax code to unlock your maximum refund</p>
            </li>
          </ul>
        </div>
      </div>
      <div className={indexStyles.greyBackground}>
        <div className="container">
          <ul>
            <li>
              <Link to="/services">
                <img src={require("../images/user.svg")} alt="user icon" />
                <h5>Individuals</h5>
                <p>
                  Taxes, investments, retirement, and more. Let us help you
                  protect your personal assets.
                </p>
              </Link>
            </li>
            <li>
              <Link to="/services">
                <img src={require("../images/group.svg")} alt="group icon" />
                <h5>Corporations</h5>
                <p>
                  Small businesses, larger corporations, and everything in
                  between. We’ll help ensure you’re operating at peak
                  performance.
                </p>
              </Link>
            </li>
            <li>
              <Link to="/services">
                <img
                  src={require("../images/computer.svg")}
                  alt="computer icon"
                />
                <h5>QuickBooks Services</h5>
                <p>
                  With certified specialists on staff, we can make sure you get
                  the most out of Intuit’s premier accounting software.
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={indexStyles.newsletterContainer}>
        <div className="container">
          <h2>Sign up for our monthly newsletter</h2>
          <p>
            Helpful articles and insights from the Tax Artists themselves,
            delivered right to your inbox.
          </p>
          <div className={indexStyles.newsletterForm}>
            <input
              type="email"
              name="email"
              placeholder="you@example.com"
              onChange={e => setEmail(e.target.value)}
            />
            <input
              type="submit"
              value="Sign Me Up"
              onClick={newsletterSignUp}
            />
          </div>
          <div className={indexStyles.newsletterConfirmation}>
            <p>{signUpResponse ? signUpResponse : ""}</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
